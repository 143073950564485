import { Link, Outlet, useHref, useParams } from 'react-router-dom';
import { Hammer, Box, SquareSlash, Store } from 'lucide-react';
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, BreadcrumbList, BreadcrumbPage, BreadcrumbSeparator } from '@/components/ui/breadcrumb';
import { useSectorType } from '@/data/sectorType';
import { Loading } from '@/components/ui/loading';
import { Error } from '@/components/ui/error';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';

const ViewSectorTypePage = () => {
  const params = useParams();
  const sectorType = useSectorType(params.sectorTypeUid);
  const pathname = useHref();

  if (sectorType.isLoading && !sectorType.data) {
    return <Loading />;
  }

  if (sectorType.error) {
    return <Error message="An error occurred loading sector type" />;
  }

  return (
    <>
      <Breadcrumb className="mb-4">
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink asChild>
              <Link to="/sector-type">Sector Types</Link>
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbPage>{sectorType.data.label}</BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>
      <Card>
        <CardHeader>
          <div className="flex items-center gap-2">
            <div className="flex-auto space-y-1.5">
              <CardTitle>{sectorType.data.label}</CardTitle>
              <CardDescription>{sectorType.data.description}</CardDescription>
            </div>
          </div>
        </CardHeader>
        <CardContent>
          <Tabs value={pathname} className="w-full">
            <TabsList className="w-full">
              <TabsTrigger value={`/sector-type/${params.sectorTypeUid}`} asChild>
                <Link to={`/sector-type/${params.sectorTypeUid}`}>
                  <Hammer />
                  Overview
                </Link>
              </TabsTrigger>
              <TabsTrigger value={`/sector-type/${params.sectorTypeUid}/sector-sub-type`} asChild>
                <Link to={`/sector-type/${params.sectorTypeUid}/sector-sub-type`}>
                  <Box />
                  Product Types
                </Link>
              </TabsTrigger>
              <TabsTrigger value={`/sector-type/${params.sectorTypeUid}/division`} asChild>
                <Link to={`/sector-type/${params.sectorTypeUid}/division`}>
                  <SquareSlash />
                  Divisions
                </Link>
              </TabsTrigger>
              <TabsTrigger value={`/sector-type/${params.sectorTypeUid}/market-design-field`} asChild>
                <Link to={`/sector-type/${params.sectorTypeUid}/market-design-field`}>
                  <Store />
                  Market Design Fields
                </Link>
              </TabsTrigger>
            </TabsList>
          </Tabs>

          <div className="pt-4">
            <Outlet />
          </div>
        </CardContent>
      </Card>
    </>
  );
};

export default ViewSectorTypePage;
