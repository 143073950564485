import axios from 'axios';
import useSWR from 'swr';

const useMarketDesignFields = (sectorTypeUid) => {
  const url = sectorTypeUid ? `/market-design-field?sector_type_uid=${sectorTypeUid}` : null;
  const swr = useSWR(url, async (url) => {
    try {
      const result = await axios.get(url);
      return result.data;
    } catch (err) {
      throw Error(err);
    }
  });

  return swr;
};

const usePostMarketDesignField = () => {
  const postMarketDesignField = async (payload) => {
    try {
      const url = '/admin/market-design-field';
      const result = await axios.post(url, payload);
      return result.data;
    } catch (error) {
      throw Error(error);
    }
  };
  return postMarketDesignField;
};

const usePutMarketDesignField = () => {
  const putMarketDesignField = async (marketDesignFieldUid, payload) => {
    try {
      const url = `/admin/market-design-field/${marketDesignFieldUid}`;
      const result = await axios.put(url, payload);
      return result.data;
    } catch (error) {
      throw Error(error);
    }
  };
  return putMarketDesignField;
};

const useMarketDesignFieldObject = (sectorTypeUid, uid) => {
  const marketDesignFields = useMarketDesignFields(sectorTypeUid);
  return marketDesignFields.data.content[uid];
};

export { useMarketDesignFields, usePostMarketDesignField, usePutMarketDesignField, useMarketDesignFieldObject };
