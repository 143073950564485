import { useParams } from 'react-router-dom';
import { SquareSlash } from 'lucide-react';

import { Error } from '@/components/ui/error';
import { Loading } from '@/components/ui/loading';
import { Table, TableBody, TableHead, TableHeader, TableRow, TableCell } from '@/components/ui/table';
import { Empty, EmptyIcon, EmptyTitle, EmptyDescription } from '@/components/ui/empty';

import { useDivisions } from '@/data/division';

const List = () => {
  const params = useParams();
  const divisions = useDivisions(params.sectorTypeUid);

  if (divisions.isLoading && !divisions.data) {
    return <Loading />;
  }

  if (divisions.error) {
    return <Error message="An error occurred loading divisions" />;
  }

  if (divisions.data.query.length === 0) {
    return (
      <div className="space-y-4">
        <div className="flex justify-between items-center">
          <h2 className="text-xl font-semibold">Divisions</h2>
        </div>

        <Empty>
          <EmptyIcon>
            <SquareSlash />
          </EmptyIcon>
          <EmptyTitle>No Divisions Found</EmptyTitle>
          <EmptyDescription>No divisions have been created yet</EmptyDescription>
        </Empty>
      </div>
    );
  }

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center">
        <h2 className="text-xl font-semibold">Divisions</h2>
      </div>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Code</TableHead>
            <TableHead>Name</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {divisions.data.query.map((divisionUid) => {
            const division = divisions.data.content[divisionUid];

            return (
              <TableRow key={division.uid}>
                <TableCell>{division.division_code}</TableCell>
                <TableCell>{division.division_name}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
};

export default List;
