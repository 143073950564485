import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { Loader2 } from 'lucide-react';
import { useParams } from 'react-router-dom';

import { marketDesignFieldSchema } from './_validators';
import FormGeneral from './components/common/FormGeneral';

import { Button } from '@/components/ui/button';
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Form } from '@/components/ui/form';
import { Error } from '@/components/ui/error';

import { usePostMarketDesignField } from '@/data/marketDesignField';

const AddMarketDesignFieldDialog = ({ onClose, onSuccess }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [apiError, setApiError] = useState('');
  const postMarketDesignField = usePostMarketDesignField();
  const params = useParams();

  const form = useForm({
    resolver: zodResolver(marketDesignFieldSchema),
    defaultValues: {
      code: '',
      label: '',
      description: '',
      value_unit_label: 'None',
      value_unit_decimals: '0'
    }
  });

  const onSubmit = async (values) => {
    try {
      setIsSubmitting(true);
      setApiError('');
      const payload = {
        sector_type_uid: params.sectorTypeUid,
        code: values.code,
        label: values.label,
        value_unit_label: values.value_unit_label === 'None' ? '' : values.value_unit_label,
        value_unit_decimals: parseInt(values.value_unit_decimals),
        description: values.description
      };
      const newMarketDesignField = await postMarketDesignField(payload);
      await onSuccess(newMarketDesignField);
      onClose();
    } catch (err) {
      setApiError(err.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px]">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-2">
            <DialogHeader>
              <DialogTitle>Add Market Design Field</DialogTitle>
              <DialogDescription>Create a new market design field</DialogDescription>
            </DialogHeader>
            <FormGeneral />
            {apiError && <Error message={apiError} />}
            <DialogFooter>
              <Button type="submit" disabled={isSubmitting}>
                {isSubmitting && <Loader2 className="animate-spin" />}Add Market Design Field
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default AddMarketDesignFieldDialog;
