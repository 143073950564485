export const VALUE_UNITS = {
  ACRE: 'Acre',
  CY: 'CY',
  DATE: 'Date',
  DU_ACRE: 'DU/Acre',
  EACH: 'EA',
  LF: 'LF',
  LS: 'LS',
  MONTHS: 'Months',
  PERCENTAGE: '%',
  RATIO: 'Ratio',
  SF: 'SF',
  SF_STALL: 'SF/Stall',
  SF_UNIT: 'SF/Unit',
  STALL_UNIT: 'Stall/Unit',
  USD: 'USD',
  USD_DOLLAR: 'USD ($)',
  YES_NO: 'Yes/No',
  FEET: 'Feet',
  DOLLAR_PER_HOUR: '$/hour'
};
