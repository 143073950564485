import { Link } from 'react-router-dom';
import { CirclePlus } from 'lucide-react';
import ListSectorTypes from './components/list/ListSectorTypes';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Button } from '@/components/ui/button';

const List = () => {
  return (
    <Card>
      <CardHeader>
        <div className="flex justify-between items-center">
          <CardTitle>Sector Types</CardTitle>
          <Button asChild>
            <Link to="/sector-type/add">
              <CirclePlus />
              Add Sector Type
            </Link>
          </Button>
        </div>
      </CardHeader>
      <CardContent className="space-y-4">
        <ListSectorTypes />
      </CardContent>
    </Card>
  );
};

export default List;
