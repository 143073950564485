import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { Box, CirclePlus } from 'lucide-react';

import AddMarketDesignFieldDialog from './AddMarketDesignFieldDialog';
import MarketDesignFieldRow from './components/list/MarketDesignFieldRow';

import { Error } from '@/components/ui/error';
import { Loading } from '@/components/ui/loading';
import { Table, TableBody, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { Empty, EmptyIcon, EmptyTitle, EmptyDescription } from '@/components/ui/empty';
import { Button } from '@/components/ui/button';

import { mutateListOnPost } from '@/helpers/swr';

import { useMarketDesignFields } from '@/data/marketDesignField';

const ListMarketDesignFields = () => {
  const [isAddOpen, setIsAddOpen] = useState(false);
  const params = useParams();
  const marketDesignFields = useMarketDesignFields(params.sectorTypeUid);

  if (marketDesignFields.isLoading && !marketDesignFields.data) {
    return <Loading />;
  }

  if (marketDesignFields.error) {
    return <Error message="An error occurred loading market design fields" />;
  }

  const handleOpenAdd = () => {
    setIsAddOpen(true);
  };
  const handleCloseAdd = () => {
    setIsAddOpen(false);
  };

  const handleAddSuccess = async (newMarketDesignField) => {
    await marketDesignFields.mutate((current) => mutateListOnPost(current, newMarketDesignField), false);
  };

  if (marketDesignFields.data.query.length === 0) {
    return (
      <div className="space-y-4">
        <div className="flex justify-between items-center">
          <h2 className="text-xl font-semibold">Market Design Fields</h2>
          <Button size="sm" onClick={handleOpenAdd}>
            <CirclePlus />
            Add Market Design Field
          </Button>
        </div>

        <Empty>
          <EmptyIcon>
            <Box />
          </EmptyIcon>
          <EmptyTitle>No Market Design Fields Found</EmptyTitle>
          <EmptyDescription>Try adding a new market design field</EmptyDescription>
        </Empty>
        {isAddOpen && <AddMarketDesignFieldDialog onClose={handleCloseAdd} onSuccess={handleAddSuccess} />}
      </div>
    );
  }

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center">
        <h2 className="text-xl font-semibold">Market Design Fields</h2>
        <Button size="sm" onClick={handleOpenAdd}>
          <CirclePlus />
          Add Market Design Field
        </Button>
      </div>
      <Table>
        <TableHeader>
          <TableRow>
            {/* <TableHead>Code</TableHead> */}
            <TableHead>Label</TableHead>
            <TableHead>Value Unit</TableHead>
            <TableHead className="text-right">Value Unit Decimals</TableHead>
            <TableHead></TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {marketDesignFields.data.query.map((marketDesignFieldUid) => (
            <MarketDesignFieldRow key={marketDesignFieldUid} uid={marketDesignFieldUid} />
          ))}
        </TableBody>
      </Table>
      {isAddOpen && <AddMarketDesignFieldDialog onClose={handleCloseAdd} onSuccess={handleAddSuccess} />}
    </div>
  );
};

export default ListMarketDesignFields;
