import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { LinkIcon, Loader2, MapPin, Phone, Upload } from 'lucide-react';
import { useDropzone } from 'react-dropzone';
import { useCompany, usePutCompany, useUploadCompanyLogo } from '@/data/company';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import { Button } from '@/components/ui/button';
import { Error } from '@/components/ui/error';
import { Checkbox } from '@/components/ui/checkbox';
import { Separator } from '@/components/ui/separator';
import { useSectorTypes } from '@/data/sectorType';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';

const formSchema = z.object({
  company_name: z.string().min(1),
  company_description: z.string(),
  address: z.string(),
  phone: z.string(),
  website: z.string().url().optional().or(z.literal('')),
  is_market_data: z.boolean(),
  is_market_data_visible: z.boolean(),
  is_v1_estimate: z.boolean(),
  is_v2_estimate: z.boolean(),
  is_bid_management: z.boolean(),
  default_sector_type_uid: z.string()
});

const EditCompany = () => {
  const params = useParams();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [apiError, setApiError] = useState('');
  const putCompany = usePutCompany();
  const company = useCompany(params.companyUid);
  const sectorTypes = useSectorTypes();
  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      company_name: company.data.company_name,
      company_description: company.data.company_description,
      address: company.data.address,
      phone: company.data.phone,
      website: company.data.website,
      is_market_data: company.data.is_market_data,
      is_market_data_visible: company.data.is_market_data_visible,
      is_v1_estimate: company.data.is_v1_estimate,
      is_v2_estimate: company.data.is_v2_estimate,
      is_bid_management: company.data.is_bid_management,
      default_sector_type_uid: company.data.default_sector_type_uid
    }
  });

  const onSubmit = async (values) => {
    setIsSubmitting(true);
    setApiError('');
    try {
      const updatedCompany = await putCompany(params.companyUid, values);
      company.mutate(updatedCompany, false);
      setIsSubmitting(false);
    } catch (err) {
      setIsSubmitting(false);
      setApiError(err.message);
    }
  };

  const { uploadCompanyLogo, progress } = useUploadCompanyLogo();

  const handleUploadSuccess = async (newCompany) => {
    await company.mutate(newCompany, false);
  };

  const handleDropFiles = (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      uploadCompanyLogo(acceptedFiles[0], params.companyUid, handleUploadSuccess);
    }
  };

  const dropzone = useDropzone({
    accept: {
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': ['.png']
    },
    onDrop: handleDropFiles
  });

  return (
    <div className="space-y-4">
      <h2 className="text-xl font-semibold">Company Info</h2>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
          <div className="flex items-stretch gap-4">
            <div className="relative space-y-2 border rounded p-2 flex flex-col">
              <div className="flex-auto flex items-center justify-center">
                <div className="w-[130px] h-[130px] bg-background flex items-center justify-center relative p-1">
                  {company.data.logo_url && (
                    <img src={company.data.logo_url} alt={company.data.company_name} className="max-w-[100px] max-h-[100px] object-cover block" loading="lazy" />
                  )}
                  {!company.data.logo_url && <div className="text-xs uppercase text-muted-foreground">No Logo</div>}
                </div>
              </div>
              <Separator />
              <Button variant="outline" size="sm" {...dropzone.getRootProps()} disabled={progress > 0} type="button">
                {progress > 0 ? <Loader2 className="animate-spin !w-3 !h-3" /> : <Upload className="!w-3 !h-3" />}
                Upload Logo
              </Button>
              <input {...dropzone.getInputProps()} accept="image/jpeg, image/png, .jpg" className="hidden" />
            </div>
            <div className="flex-1 space-y-4 border-l pl-4">
              <FormField
                control={form.control}
                name="company_name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Company Name</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="company_description"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Company Description</FormLabel>
                    <FormControl>
                      <Textarea {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className="grid grid-cols-3 gap-4">
                <FormField
                  control={form.control}
                  name="address"
                  startIcon={<MapPin />}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Address</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="phone"
                  startIcon={<Phone />}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Phone</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="website"
                  startIcon={<LinkIcon />}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Website</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </div>
          </div>
          <Separator />
          <div className="space-y-4">
            <div>
              <h3 className="font-medium text-lg">Market Data</h3>
              <p className="text-muted-foreground text-sm">Configure market data preferences for this company</p>
            </div>
            <FormField
              control={form.control}
              name="is_market_data"
              render={({ field }) => (
                <FormItem className="flex items-center gap-2 space-y-0">
                  <FormControl className="flex items-end">
                    <Checkbox checked={field.value} onCheckedChange={field.onChange} />
                  </FormControl>
                  <FormLabel>Include company data in aggregated market data</FormLabel>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="is_market_data_visible"
              render={({ field }) => (
                <FormItem className="flex items-center gap-2 space-y-0">
                  <FormControl className="flex items-end">
                    <Checkbox checked={field.value} onCheckedChange={field.onChange} />
                  </FormControl>
                  <FormLabel>Allow this company to view market data</FormLabel>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="default_sector_type_uid"
              render={({ field }) => (
                <FormItem className="">
                  <FormLabel>Default Market Data Sector Type</FormLabel>
                  <FormControl>
                    <Select value={field.value} onValueChange={field.onChange}>
                      <SelectTrigger>
                        <SelectValue placeholder="Select a sector type" />
                      </SelectTrigger>
                      <SelectContent>
                        {sectorTypes.data.query.map((sectorTypeUid) => (
                          <SelectItem key={sectorTypeUid} value={sectorTypeUid}>
                            {sectorTypes.data.content[sectorTypeUid].label}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </FormControl>
                </FormItem>
              )}
            />
          </div>
          <Separator />
          <div className="space-y-4">
            <div>
              <h3 className="font-medium text-lg">Visible Features</h3>
              <p className="text-muted-foreground text-sm">Configure what features this company can view within Zebel</p>
            </div>
            <FormField
              control={form.control}
              name="is_v1_estimate"
              render={({ field }) => (
                <FormItem className="flex items-center gap-2 space-y-0">
                  <FormControl className="flex items-end">
                    <Checkbox checked={field.value} onCheckedChange={field.onChange} />
                  </FormControl>
                  <FormLabel>Allow this company to view V1 Estimate Page</FormLabel>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="is_v2_estimate"
              render={({ field }) => (
                <FormItem className="flex items-center gap-2 space-y-0">
                  <FormControl className="flex items-end">
                    <Checkbox checked={field.value} onCheckedChange={field.onChange} />
                  </FormControl>
                  <FormLabel>Allow this company to view V2 Estimate Page</FormLabel>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="is_bid_management"
              render={({ field }) => (
                <FormItem className="flex items-center gap-2 space-y-0">
                  <FormControl className="flex items-end">
                    <Checkbox checked={field.value} onCheckedChange={field.onChange} />
                  </FormControl>
                  <FormLabel>Allow this company to view bid management</FormLabel>
                </FormItem>
              )}
            />
          </div>

          {apiError && <Error message={apiError} />}
          <div className="flex justify-end">
            <Button type="submit" disabled={isSubmitting}>
              {isSubmitting && <Loader2 className="animate-spin" />}
              Save Company
            </Button>
          </div>
        </form>
      </Form>
    </div>
  );
};

export default EditCompany;
