import axios from 'axios';
import useSWR from 'swr';

const useSectorTypes = () => {
  const url = '/sector-type';
  const swr = useSWR(url, async (url) => {
    try {
      const result = await axios.get(url);
      return result.data;
    } catch (err) {
      throw Error(err);
    }
  });

  return swr;
};

const useSectorType = (sectorTypeUid) => {
  const url = `/admin/sector-type/${sectorTypeUid}`;
  const swr = useSWR(url, async (url) => {
    try {
      const result = await axios.get(url);
      return result.data;
    } catch (err) {
      throw Error(err);
    }
  });

  return swr;
};

const usePostSectorType = () => {
  const postSectorType = async (payload) => {
    try {
      const url = '/admin/sector-type';
      const result = await axios.post(url, payload);
      return result.data;
    } catch (error) {
      throw Error(error);
    }
  };
  return postSectorType;
};

const usePutSectorType = () => {
  const putSectorType = async (sectorTypeUid, payload) => {
    try {
      const url = `/admin/sector-type/${sectorTypeUid}`;
      const result = await axios.put(url, payload);
      return result.data;
    } catch (error) {
      throw Error(error);
    }
  };
  return putSectorType;
};

export { useSectorTypes, useSectorType, usePostSectorType, usePutSectorType };
