import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { Loader2 } from 'lucide-react';
import { useParams } from 'react-router-dom';

import { addSchema } from './_validators';

import { Button } from '@/components/ui/button';
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Error } from '@/components/ui/error';
import { usePostSectorSubType } from '@/data/sectorSubType';

const AddSectorSubTypeDialog = ({ onClose, onSuccess }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [apiError, setApiError] = useState('');
  const postSectorSubType = usePostSectorSubType();
  const params = useParams();

  const form = useForm({
    resolver: zodResolver(addSchema),
    defaultValues: {
      label: '',
      description: ''
    }
  });

  const onSubmit = async (values) => {
    try {
      setIsSubmitting(true);
      setApiError('');
      const payload = {
        sector_type_uid: params.sectorTypeUid,
        label: values.label,
        description: values.description
      };
      const newSectorSubType = await postSectorSubType(payload);
      await onSuccess(newSectorSubType);
      onClose();
    } catch (err) {
      setApiError(err.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px]">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-2">
            <DialogHeader>
              <DialogTitle>Add Product Type</DialogTitle>
              <DialogDescription>Create a new product type</DialogDescription>
            </DialogHeader>
            <FormField
              control={form.control}
              name="label"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Label</FormLabel>
                  <FormControl>
                    <Input {...field} placeholder="Enter label" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Description</FormLabel>
                  <FormControl>
                    <Input {...field} placeholder="Enter description" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            {apiError && <Error message={apiError} />}
            <DialogFooter>
              <Button type="submit" disabled={isSubmitting}>
                {isSubmitting && <Loader2 className="animate-spin" />}Add Product Type
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default AddSectorSubTypeDialog;
