import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { Box, CirclePlus } from 'lucide-react';

import AddSectorSubTypeDialog from './AddSectorSubTypeDialog';
import SectorSubTypeRow from './components/list/SectorSubTypeRow';

import { Error } from '@/components/ui/error';
import { Loading } from '@/components/ui/loading';
import { Table, TableBody, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { Empty, EmptyIcon, EmptyTitle, EmptyDescription } from '@/components/ui/empty';
import { Button } from '@/components/ui/button';

import { mutateListOnPost } from '@/helpers/swr';

import { useSectorSubTypes } from '@/data/sectorSubType';

const ListSectorSubTypes = () => {
  const [isAddOpen, setIsAddOpen] = useState(false);
  const params = useParams();
  const sectorSubTypes = useSectorSubTypes(params.sectorTypeUid);

  if (sectorSubTypes.isLoading && !sectorSubTypes.data) {
    return <Loading />;
  }

  if (sectorSubTypes.error) {
    return <Error message="An error occurred loading sector sub types" />;
  }

  const handleOpenAdd = () => {
    setIsAddOpen(true);
  };
  const handleCloseAdd = () => {
    setIsAddOpen(false);
  };

  const handleAddSuccess = async (newSectorSubType) => {
    await sectorSubTypes.mutate((current) => mutateListOnPost(current, newSectorSubType), false);
  };

  if (sectorSubTypes.data.query.length === 0) {
    return (
      <div className="space-y-4">
        <div className="flex justify-between items-center">
          <h2 className="text-xl font-semibold">Product Types</h2>
          <Button size="sm" onClick={handleOpenAdd}>
            <CirclePlus />
            Add Product Type
          </Button>
        </div>

        <Empty>
          <EmptyIcon>
            <Box />
          </EmptyIcon>
          <EmptyTitle>No Product Types Found</EmptyTitle>
          <EmptyDescription>Try adding a new product type</EmptyDescription>
        </Empty>
        {isAddOpen && <AddSectorSubTypeDialog onClose={handleCloseAdd} onSuccess={handleAddSuccess} />}
      </div>
    );
  }

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center">
        <h2 className="text-xl font-semibold">Product Types</h2>
        <Button size="sm" onClick={handleOpenAdd}>
          <CirclePlus />
          Add Product Type
        </Button>
      </div>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Label</TableHead>
            <TableHead>Description</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {sectorSubTypes.data.query.map((sectorSubTypeUid) => (
            <SectorSubTypeRow key={sectorSubTypeUid} uid={sectorSubTypeUid} />
          ))}
        </TableBody>
      </Table>
      {isAddOpen && <AddSectorSubTypeDialog onClose={handleCloseAdd} onSuccess={handleAddSuccess} />}
    </div>
  );
};

export default ListSectorSubTypes;
