import { z } from 'zod';

export const addSchema = z.object({
  label: z.string().min(1).max(100),
  description: z.string().max(500).optional()
});

export const editSchema = z.object({
  label: z.string().min(1).max(100),
  description: z.string().max(500).optional(),
  default_market_design_field_uid: z.string().optional()
});
