import { EllipsisVertical, Pencil } from 'lucide-react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { TableRow, TableCell } from '@/components/ui/table';
import { DropdownMenu, DropdownMenuContent, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuItem, DropdownMenuTrigger } from '@/components/ui/dropdown-menu';
import { Button } from '@/components/ui/button';

import { mutateListOnPut } from '@/helpers/swr';

import EditSectorSubTypeDialog from '@/pages/sectorSubType/EditSectorSubTypeDialog';

import { useSectorSubTypeObject, useSectorSubTypes } from '@/data/sectorSubType';

const SectorSubTypeRow = ({ uid }) => {
  const params = useParams();
  const sectorSubTypes = useSectorSubTypes(params.sectorTypeUid);
  const sectorSubType = useSectorSubTypeObject(params.sectorTypeUid, uid);
  const [isEditOpen, setIsEditOpen] = useState(false);

  const handleOpenEdit = () => {
    setIsEditOpen(true);
  };
  const handleCloseEdit = () => {
    setIsEditOpen(false);
  };

  const handleEditSuccess = async (updatedSectorSubType) => {
    await sectorSubTypes.mutate((current) => mutateListOnPut(current, updatedSectorSubType), false);
  };

  return (
    <>
      <TableRow>
        <TableCell className="font-medium">{sectorSubType.label}</TableCell>
        <TableCell>{sectorSubType.description}</TableCell>
        <TableCell className="text-right py-2">
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="icon" size="icon" aria-label="Open menu">
                <EllipsisVertical />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuLabel>{sectorSubType.label}</DropdownMenuLabel>
              <DropdownMenuSeparator />
              <DropdownMenuItem onClick={handleOpenEdit}>
                <Pencil />
                Edit Product Type
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </TableCell>
      </TableRow>
      {isEditOpen && <EditSectorSubTypeDialog uid={uid} onClose={handleCloseEdit} onSuccess={handleEditSuccess} />}
    </>
  );
};

export default SectorSubTypeRow;
