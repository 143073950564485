import { Link } from 'react-router-dom';
import { Hammer } from 'lucide-react';
import { Error } from '@/components/ui/error';
import { Loading } from '@/components/ui/loading';
import { useSectorTypes } from '@/data/sectorType';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { Empty, EmptyIcon, EmptyTitle, EmptyDescription } from '@/components/ui/empty';

const ListSectorTypes = () => {
  const sectorTypes = useSectorTypes();

  if (sectorTypes.isLoading && !sectorTypes.data) {
    return <Loading />;
  }

  if (sectorTypes.error) {
    return <Error message="An error occurred loading sector types" />;
  }

  if (sectorTypes.data.query.length === 0) {
    return (
      <Empty>
        <EmptyIcon>
          <Hammer />
        </EmptyIcon>
        <EmptyTitle>No Sector Types Found</EmptyTitle>
        <EmptyDescription>Try adding a new sector type</EmptyDescription>
      </Empty>
    );
  }

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>Label</TableHead>
          <TableHead>Description</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {sectorTypes.data.query.map((sectorTypeUid) => {
          const sectorType = sectorTypes.data.content[sectorTypeUid];
          return (
            <TableRow key={sectorTypeUid}>
              <TableCell className="font-medium p-0">
                <Link to={`/sector-type/${sectorType.uid}`} className="p-4 block">
                  {sectorType.label}
                </Link>
              </TableCell>
              <TableCell className="p-0">
                <Link to={`/sector-type/${sectorType.uid}`} className="p-4 block">
                  {sectorType.description}
                </Link>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default ListSectorTypes;
