import { z } from 'zod';

export const marketDesignFieldSchema = z.object({
  code: z.string().max(50),
  label: z.string().min(1).max(100),
  description: z.string().max(500).optional(),
  value_unit_label: z.string().max(100),
  value_unit_decimals: z.string().refine((val) => ['0', '1', '2', '3', '4', '5'].includes(val), {
    message: 'Decimals must be 0, 1, 2, 3, 4, or 5'
  })
});
