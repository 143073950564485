import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Loader2 } from 'lucide-react';

import { editSchema } from './_validator';

import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { Error } from '@/components/ui/error';
import { Textarea } from '@/components/ui/textarea';
import { Loading } from '@/components/ui/loading';
import { useSectorType, usePutSectorType } from '@/data/sectorType';
import { useMarketDesignFields } from '@/data/marketDesignField';
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from '@/components/ui/select';

const EditSectorType = () => {
  const params = useParams();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [apiError, setApiError] = useState('');
  const putSectorType = usePutSectorType();
  const sectorType = useSectorType(params.sectorTypeUid);
  const marketDesignFields = useMarketDesignFields(params.sectorTypeUid);

  const form = useForm({
    resolver: zodResolver(editSchema),
    defaultValues: {
      label: sectorType.data.label,
      description: sectorType.data.description,
      default_market_design_field_uid: sectorType.data.default_market_design_field_uid
    }
  });

  const onSubmit = async (values) => {
    try {
      setIsSubmitting(true);
      setApiError('');
      const updatedSectorType = await putSectorType(params.sectorTypeUid, values);
      sectorType.mutate(updatedSectorType, false);
      setIsSubmitting(false);
    } catch (err) {
      setIsSubmitting(false);
      setApiError(err.message);
    }
  };

  if (sectorType.isLoading && !sectorType.data) {
    return <Loading />;
  }

  if (marketDesignFields.isLoading && !marketDesignFields.data) {
    return <Loading />;
  }

  if (sectorType.error) {
    return <Error message="An error occurred loading sector type" />;
  }

  if (marketDesignFields.error) {
    return <Error message="An error occurred loading market design fields" />;
  }

  return (
    <div className="space-y-4">
      <h2 className="text-xl font-semibold">Sector Type Info</h2>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
          <div className="space-y-4">
            <FormField
              control={form.control}
              name="label"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Label</FormLabel>
                  <FormControl>
                    <Input {...field} placeholder="Enter sector type label" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Description</FormLabel>
                  <FormControl>
                    <Textarea {...field} placeholder="Enter sector type description" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="default_market_design_field_uid"
              render={({ field }) => (
                <FormItem className="">
                  <FormLabel>Default Market Design Field UOM</FormLabel>
                  <FormControl>
                    <Select value={field.value} onValueChange={field.onChange}>
                      <SelectTrigger>
                        <SelectValue placeholder="Select a market design field" />
                      </SelectTrigger>
                      <SelectContent>
                        {marketDesignFields.data.query.map((marketDesignFieldUid) => (
                          <SelectItem key={marketDesignFieldUid} value={marketDesignFieldUid}>
                            {marketDesignFields.data.content[marketDesignFieldUid].label}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </FormControl>
                </FormItem>
              )}
            />
          </div>

          {apiError && <Error message={apiError} />}
          <div className="flex justify-end">
            <Button type="submit" disabled={isSubmitting}>
              {isSubmitting && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
              Save Changes
            </Button>
          </div>
        </form>
      </Form>
    </div>
  );
};

export default EditSectorType;
