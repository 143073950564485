import axios from 'axios';
import useSWR from 'swr';

const useSectorSubTypes = (sectorTypeUid) => {
  const url = sectorTypeUid ? `/admin/sector-sub-type?sector_type_uid=${sectorTypeUid}` : null;
  const swr = useSWR(url, async (url) => {
    try {
      const result = await axios.get(url);
      return result.data;
    } catch (err) {
      throw Error(err);
    }
  });

  return swr;
};

const usePostSectorSubType = () => {
  const postSectorSubType = async (payload) => {
    try {
      const url = '/admin/sector-sub-type';
      const result = await axios.post(url, payload);
      return result.data;
    } catch (error) {
      throw Error(error);
    }
  };
  return postSectorSubType;
};

const usePutSectorSubType = () => {
  const putSectorSubType = async (sectorSubTypeUid, payload) => {
    try {
      const url = `/admin/sector-sub-type/${sectorSubTypeUid}`;
      const result = await axios.put(url, payload);
      return result.data;
    } catch (error) {
      throw Error(error);
    }
  };
  return putSectorSubType;
};

const useSectorSubTypeObject = (sectorTypeUid, uid) => {
  const sectorSubTypes = useSectorSubTypes(sectorTypeUid);
  return sectorSubTypes.data.content[uid];
};

export { useSectorSubTypes, usePostSectorSubType, usePutSectorSubType, useSectorSubTypeObject };
