import { EllipsisVertical, Pencil } from 'lucide-react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { TableRow, TableCell } from '@/components/ui/table';
import { DropdownMenu, DropdownMenuContent, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuItem, DropdownMenuTrigger } from '@/components/ui/dropdown-menu';
import { Button } from '@/components/ui/button';

import { mutateListOnPut } from '@/helpers/swr';

import EditMarketDesignFieldDialog from '@/pages/marketDesignField/EditMarketDesignFieldDialog';

import { useMarketDesignFieldObject, useMarketDesignFields } from '@/data/marketDesignField';

const MarketDesignFieldRow = ({ uid }) => {
  const params = useParams();
  const marketDesignFields = useMarketDesignFields(params.sectorTypeUid);
  const marketDesignField = useMarketDesignFieldObject(params.sectorTypeUid, uid);
  const [isEditOpen, setIsEditOpen] = useState(false);

  const handleOpenEdit = () => {
    setIsEditOpen(true);
  };
  const handleCloseEdit = () => {
    setIsEditOpen(false);
  };

  const handleEditSuccess = async (updatedMarketDesignField) => {
    await marketDesignFields.mutate((current) => mutateListOnPut(current, updatedMarketDesignField), false);
  };

  return (
    <>
      <TableRow>
        {/* <TableCell className="font-medium">{marketDesignField.code}</TableCell> */}
        <TableCell>{marketDesignField.label}</TableCell>
        <TableCell>{marketDesignField.value_unit_label}</TableCell>
        <TableCell className="text-right">{marketDesignField.value_unit_decimals}</TableCell>
        <TableCell className="text-right py-2">
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="icon" size="icon" aria-label="Open menu">
                <EllipsisVertical />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuLabel>{marketDesignField.label}</DropdownMenuLabel>
              <DropdownMenuSeparator />
              <DropdownMenuItem onClick={handleOpenEdit}>
                <Pencil />
                Edit Market Design Field
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </TableCell>
      </TableRow>
      {isEditOpen && <EditMarketDesignFieldDialog uid={uid} onClose={handleCloseEdit} onSuccess={handleEditSuccess} />}
    </>
  );
};

export default MarketDesignFieldRow;
